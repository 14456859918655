import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { mergeMap, tap } from 'rxjs/operators';
import { AppConfigsService } from './arsenal-components/services/app-configs.service';
import { XpoLtlLoggedInUserService } from './arsenal-components/services/logged-in-user.service';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title: string;
  build: string;
  routes: XpoShellRoute[] = [];

  apiComponentRoute = `/${AppRoutes.COMPONENTS_PAGE}`;
  aemRoute = `/${AppRoutes.AEM_PAGE}`;
  componentOwnershipRoute = `/${AppRoutes.COMPONENT_OWNERSHIP}`;

  constructor(
    private configManagerService: ConfigManagerService,
    private router: Router,
    private appConfigsService: AppConfigsService,
    private loggedInUserService: XpoLtlLoggedInUserService,
    private xpoLtlAuthService: XpoLtlAuthenticationService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    /** Shell setup */
    this.title = 'Arsenal Tool';
    this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }

  get isUserMaintAdmin$() {
    return this.appConfigsService.isUserMaintAdmin.asObservable();
  }

  ngOnInit(): void {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    const prod = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);

    this.xpoLtlAuthService
      .initAuthSetup$(region)
      .pipe(
        mergeMap((regionInfo) => {
          return this.loggedInUserService.loggedInUser$.pipe(
            tap((user) => {
              this.appConfigsService.isUserMaintAdmin.next(
                prod ? true : this.getMaintAdmins(regionInfo).includes(user.emailAddress.toLowerCase())
              );
            })
          );
        }),
        tap((_) => this.appConfigsService.loggedInComplete.next(true))
      )
      .subscribe();
  }

  private getMaintAdmins(regionInfo: RegionInfo) {
    return regionInfo && regionInfo.maintAdmins ? regionInfo.maintAdmins : [];
  }
}
