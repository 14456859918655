<xpo-shell>
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <ng-container *ngIf="isUserMaintAdmin$ | async">
      <button [routerLink]="apiComponentRoute" routerLinkActive="xpo-selected">API Components</button>
      <button [routerLink]="componentOwnershipRoute" routerLinkActive="xpo-selected">API Component Ownership</button>
      <button [routerLink]="aemRoute" routerLinkActive="xpo-selected">AEM Components</button>
    </ng-container>
  </xpo-header-navigation>
  <xpo-header-actions>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <div class="content">
    <div class="app-Container-pre-content">
      <app-breadcrumbs></app-breadcrumbs>
      <app-helper-menu class="app-Container-pre-content-help-icon"></app-helper-menu>
    </div>
    <router-outlet></router-outlet>
    <xpo-footer xpoFooterSticky [version]="build"></xpo-footer>
  </div>
</xpo-shell>
<xpo-app-notification></xpo-app-notification>
